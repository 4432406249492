import { createRouter, createWebHashHistory } from 'vue-router';
import BattleCreation from './components/BattleCreation.vue';
import GameContainer from './components/GameContainer.vue';

const routes = [
  {
    path: '/',
    component: BattleCreation,
    props: true,
  },
  {
    path: '/game/:code/user/:user',
    component: GameContainer,
    props: true,
    name: 'rebels',
  },
];

export default createRouter({
  history: createWebHashHistory(),
  routes,
});
