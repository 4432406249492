<template>
  <span
    :class="{
      seaIco: state === 'sea',
      boatIco: state === 'boat',
      submarineIco: state === 'submarine',
      battleshipIco: state === 'battleship',
      aircraftIco: state === 'aircraft',
      raftIco: state === 'raft',
      woodenIco: state === 'wooden',
      fireOverlay: hit === true && sunk === false,
      sunkOverlay: sunk === true && hit === true,
    }"
  >
  </span>
</template>

<script>
export default {
  name: 'OpponentSeaSquare',
  props: {
    state: String,
    hit: Boolean,
    sunk: Boolean,
  },
};
</script>

<style scoped>
* {
  color: white;
}
span {
  padding: var(--iconPadding);
}
/* Icons */
.seaIco:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/sea2.png');
  background-size: var(--iconSize);
}
.boatIco:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/sailboat.png');
  background-size: var(--iconSize);
}
.submarineIco:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/submarine.png');
  background-size: var(--iconSize);
}
.battleshipIco:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/battleship.png');
  background-size: var(--iconSize);
}
.aircraftIco:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/aircraft-carrier.png');
  background-size: var(--iconSize);
}
.raftIco:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/raft.png');
  background-size: var(--iconSize);
}
.woodenIco:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/wooden-boat.png');
  background-size: var(--iconSize);
}
/* Overlays */
.sunkOverlay {
}
.fireOverlay {
}
</style>
