<template>
  <footer id="battleship-rebel-footer">
    <div>Photo by
      <a href="https://unsplash.com/@joshuakettle?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">Joshua Kettle</a> on <a href="https://unsplash.com/photos/fe0n33sYbKQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">Unsplash</a>
    </div>
    <div>
      <a href="https://www.flaticon.com/free-icons/sea" target="_blank" title="sea icons">Sea</a>,&nbsp;
      <a href="https://www.flaticon.com/free-icons/boat" target="_blank" title="boat icons">Boat</a>,&nbsp;
      <a href="https://www.flaticon.com/free-icons/raft" target="_blank" title="raft icons">Raft</a>,&nbsp;
      icons created by Freepik - Flaticon
    </div>
    <div>
      <a href="https://www.flaticon.com/free-icons/raft" target="_blank" title="raft icons">Additional Raft icons</a> created by Roundicons Premium - Flaticon
    </div>
    <div>
      <a href="https://www.flaticon.com/free-icons/airplane" title="aircraft icons">Airplane icon</a> created by Smashicons - Flaticon
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BattleFooter',
};
</script>

<style>
#battleship-rebel-footer {
  margin-top: 1em;
  width: 100vw;
  align-self: flex-end;
  background-color: aquamarine;
}
</style>
