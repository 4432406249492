<template>
  <span
    :class="[state,
      (hit === true && sunk === false) ? 'fireOverlay' : '',
      (sunk === true && hit === true) ? 'sunkOverlay' : '']"
    :draggable="state !== 'sea' && !state.includes('deployed')"
    @drop="dropHandler($event)"
    @dragover.prevent
    @dragenter.prevent
    @dragstart="dragstartHandler($event)"
  >
  </span>
</template>

<script>
export default {
  name: 'MySeaSquare',
  props: {
    stKey: String,
    state: String,
    hit: Boolean,
    sunk: Boolean,
  },
  emits: ['dropped'],
  methods: {
    dragstartHandler(ev) {
      // console.log('start', ev);
      if (!ev) return;
      ev.dataTransfer.setData('boat', this.state);
      ev.dataTransfer.setData('source', this.stKey);
      // ev.dataTransfer.effectAllowed = 'move';
    },
    dropHandler(ev) {
      // console.log('drop', ev);
      if (!ev) return;
      ev.preventDefault();
      const boat = ev.dataTransfer.getData('boat');
      const source = ev.dataTransfer.getData('source');
      this.$emit('dropped', { targetId: this.stKey, sourceId: source, class: boat });
    },
  },
};
</script>

<style scoped>
* {
  color: white;
}
span {
  padding: var(--iconPadding);
}
/* Icons */
.sea:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/sea2.png');
  background-size: var(--iconSize);
}
.boat:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/sailboat.png');
  background-size: var(--iconSize);
}
.submarine:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/submarine.png');
  background-size: var(--iconSize);
}
.battleship:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/battleship.png');
  background-size: var(--iconSize);
}
.aircraft:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/aircraft-carrier.png');
  background-size: var(--iconSize);
}
.raft:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/raft.png');
  background-size: var(--iconSize);
}
.wooden:after {
  display: block;
  content: "";
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url('../assets/wooden-boat.png');
  background-size: var(--iconSize);
}
/* Overlays */
.sunkOverlay {
}
.fireOverlay {
}
.deployed {
  background-color: gray;
}
</style>
