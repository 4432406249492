<template>
  <router-view></router-view>
  <BattleFooter />
</template>

<script>
import BattleFooter from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    BattleFooter,
  },
  mounted() {
  },
};
</script>

<style>
:root {
  --rowNum: 10; /* default number of rows is 10 */
  --colNum: 15;
  --iconSize: 50px;
  --iconPadding: 0.5em;
  --bgImg: url('./assets/mobile-shipwreck.jpg');
  --bgImgLrg: url('./assets/sunk-boat.jpg');
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  /* ^^ defaults ^^ */
  display: flex;
  flex-direction: column;
}
body {
  margin: 0;
  padding: 0;
  background-image: var(--bgImg);
  background-size: cover;
  background-repeat: no-repeat;
}
#app-sea-container {
  min-height: 50vh;
  max-width: 95vw;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

#sea-container, #opponent-sea-container {
  margin: 1em auto;
  max-width: 100%;
  display: grid;
  grid-template-columns:
    repeat(var(--colNum),minmax(0, calc(var(--iconSize) + var(--iconPadding)*2)));
  /* first param is num columns,
  2nd param is width of each column
  minmax allows it to squish smaller but max width of
  (min 0 max icon size + padding on both sides) */
  grid-template-rows:
    repeat(var(--rowNum), minmax(0, calc(var(--iconSize) + var(--iconPadding)*2)));
  justify-content: center;
}

#header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}
#buttonContainer {
  border-radius: 0.2em;
  background-color: white;
  width: fit-content;
  align-self: center;
  margin: 1em 0;
}

.heading {
  color: black;
  font-family:monospace;
  font-size:xx-large;
  background-color: white;
  border-radius: 0.2em;
  padding: 0.3em;
  margin: 0.5em;
}

@media (min-width: 1101px) {
  body {
    background-image: var(--bgImgLrg);
  }
}
@media (max-width: 1100px) {
  :root {
    --iconSize: 35px;
    --iconPadding: 0.3em;
  }
}
@media (max-width: 700px) {
  :root {
    --iconSize: 30px;
    --iconPadding: 0.2em;
  }
  #app-sea-container {
    max-width: 100vw;
  }
  #buttonContainer {
    width: min-content;
    min-width: 202px;
  }
}

/* copied from css scan code below here */
button {
  appearance: button;
  background-color: #1652F0;
  border: 1px solid #1652F0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.15;
  overflow: visible;
  padding: 12px 16px;
  margin: 5px 8px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: all 80ms ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
}
button:disabled {
  opacity: .3;
}
button:focus {
  outline: 0;
}
button:hover {
  background-color: #0A46E4;
  border-color: #0A46E4;
}
button:active {
  background-color: #0039D7;
  border-color: #0039D7;
}

/* CSS */
.button-target {
  --b: 3px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: #373B44;

  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 0;
}

.button-target:hover,
.button-target:focus-visible{
  background-color: initial;
  border-color: initial;
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}
.button-target:active {
  background: var(--color);
  color: #fff;
  background-color: initial;
  border-color: initial;
}
</style>
