<template>
  <div id="sea-container">
    <MySeaSquare
      v-for="(squ , index) in squares"
      :key="squ.id"
      :stKey="squ.id"
      :state="squ.state"
      :index="index"
      @dropped="dragEvent"
    />
  </div>
  <div id="boat-container">
    <MySeaSquare
      v-for="(boat, index) in boats"
      :key="boat.id"
      :stKey="boat.id"
      :state="boat.name"
      :index="index"
    />
  </div>
</template>

<script>
import MySeaSquare from './MySeaSquare.vue';

export default {
  name: 'MySeaContainer',
  components: {
    MySeaSquare,
  },
  props: ['alphabet', 'boats', 'code', 'numRows', 'numCols', 'user'],
  data() {
    return {
      squares: [],
    };
  },
  methods: {
    init() {
      const gridTotal = this.numCols * this.numRows;
      let row = 0;
      for (let i = 0; i < gridTotal; i += 1) {
        if (i % this.numCols === 0) {
          row += 1;
        }
        const letterInd = (i % this.numCols);
        const letter = this.alphabet[letterInd];
        const id = `index${i}_column_${letter}_row${row}`;
        this.squares.push({
          id, // property shorthand for property equals variable of the same name
          state: 'sea', // sea, boat, submarine
          hit: false,
          sunk: false,
        });
      }
    },
    dragEvent(data) {
      console.log('dta', data);
      this.squares.forEach((square) => {
        if (square.id === data.targetId) {
          // eslint-disable-next-line
          square.state = data.class;
        } else if (square.id === data.sourceId) {
          // eslint-disable-next-line
          square.state = 'sea';
        }
      });
      this.boats.forEach((boat) => {
        if (boat.id === data.sourceId) {
          // eslint-disable-next-line
          boat.name += ' deployed'; 
        }
      });
    },
  },
  mounted() {
    if (this.squares.length < 1 && this.numCols > 0 && this.numRows > 0) {
      this.init();
    }
  },
  updated() {
    if (this.squares.length < 1 && this.numCols > 0 && this.numRows > 0) {
      this.init();
    }
  },

};
</script>

<style scoped>
#sea-container {
  background-color: rgb(255,255,255,0.4);
  /*rgb(0, 0, 0, 0.4); black */ /* rgb(255, 238, 88, 0.3); yellowish */
  border: 1px solid white;
}
#boat-container {
  background-color: rgba(255,255,255,0.4);
  height: var(--iconSize) + var(--iconPadding) + 5px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}
</style>
