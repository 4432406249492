<template>
  <div id="opponent-sea-container">
    <OpponentSeaSquare
      v-for="(squ , index) in squares"
      :key="squ.id"
      :state="squ.state"
      :index="index"
    />
  </div>
</template>

<script>
import OpponentSeaSquare from './OpponentSeaSquare.vue';

export default {
  name: 'OpponentSeaContainer',
  components: {
    OpponentSeaSquare,
  },
  props: ['alphabet', 'code', 'numRows', 'numCols', 'user'],
  data() {
    return {
      squares: [],
    };
  },
  methods: {
    init() {
      const gridTotal = this.numCols * this.numRows;
      let row = 0;
      for (let i = 0; i < gridTotal; i += 1) {
        if (i % this.numCols === 0) {
          row += 1;
        }
        const letterInd = (i % this.numCols);
        const letter = this.alphabet[letterInd];
        const id = `opponent_column_${letter}_row_${row}`;
        this.squares.push({
          id, // property shorthand for property equals variable of the same name
          state: i === 7 ? 'submarine' : 'sea',
          // sea, boat, submarine?? not sure on opponent squares
          hit: false,
          sunk: false,
        });
      }
    },
  },
  mounted() {
    if (this.squares.length < 1 && this.numCols > 0 && this.numRows > 0) {
      this.init();
    }
  },
  updated() {
    if (this.squares.length < 1 && this.numCols > 0 && this.numRows > 0) {
      this.init();
    }
  },
};
</script>

<style scoped>
#opponent-sea-container {
  background-color: rgba(255,255,255,0.4); /* rgba(255, 0, 0, 0.4); red */
  border: 1px solid white;
}
</style>
