export default class NetworkService {
  alphabet = [
    'a', 'b', 'c', 'd', 'e',
    'f', 'g', 'h', 'i', 'j',
    'k', 'l', 'm', 'n', 'o',
    'p', 'q', 'r', 's', 't',
    'u', 'v', 'w', 'x', 'y', 'z'];
  rows = 10;
  cols = 15;
  smrows = 12;
  smcols = 8;

  boats = [
    { id: 'battleship', name: 'battleship', health: 2 },
    { id: 'submarine', name: 'submarine', health: 2 },
    { id: 'aircraft', name: 'aircraft', health: 2 },
    { id: 'boat1', name: 'boat', health: 1 },
    { id: 'boat2', name: 'boat', health: 1 },
    { id: 'raft', name: 'raft', health: 1 },
    { id: 'wooden', name: 'wooden', health: 1 },
  ];

  smboats = [
    { id: 'battleship', name: 'battleship', health: 2 },
    { id: 'submarine', name: 'submarine', health: 2 },
    { id: 'aircraft', name: 'aircraft', health: 2 },
    { id: 'boat1', name: 'boat', health: 1 },
    { id: 'wooden', name: 'wooden', health: 1 },
  ];

  fetchPostBody = (body) => ({
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  parseJson = async (response) => {
    const { ok } = response; // ok = response.ok
    const jsonz = await response.json();
    if (jsonz.data != null && ok) {
      return jsonz.data;
    }
    if (jsonz.error == null) {
      jsonz.error = 'bad request';
    }
    return jsonz.error;
  };

  async getMetadata(width) {
    function x(rows, cols, boats) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ numRows: rows, numCols: cols, boats });
        }, 100);
      });
    }

    let data = null;
    if (width > 10000) { // width in pixels, always show mobile # of squares for now
      data = await x(this.rows, this.cols, this.boats);
    } else {
      data = await x(this.smrows, this.smcols, this.smboats);
    }
    return data;
  }

  async createGame(code, user) {
    const bod = {
      code,
      user,
    };
    const fetchSettings = this.fetchPostBody(bod);
    const res = await fetch('http://localhost:62999/create', fetchSettings);
    return this.parseJson(res);
  }

  async joinGame(code, user) {
    const bod = {
      code,
      user,
    };
    const fetchSettings = this.fetchPostBody(bod);
    const res = await fetch('http://localhost:62999/join', fetchSettings);
    return this.parseJson(res);
  }

  async getStatus(code, user) {
    const res = await fetch(`http://localhost:62999/status/${code}/${user}`);
    return this.parseJson(res);
  }

  // async saveShips(data) {
  //   // todo: network request fetch
  //   return data;
  // }

  // async shoot(data) {
  //   // todo: fetch api
  //   return data;
  // }

  // async receiveShot() {
  //   // todo: long polling, signalr, or other
  //   const data = {
  //   };
  //   return data;
  // }

  getAlphabet() {
    return this.alphabet;
  }
}
