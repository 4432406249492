<template>
  <div id="header">
      <div>
        <p id="gameCode" class="heading">Code: {{ gameCode }}</p>
        <p id="nickname" class="heading">User: {{ nickname }}</p>
      </div>
      <div id="buttonContainer">
        <button @click="showMyShips = true">See my ships!</button>
        <button @click="showMyShips = false" class="button-target">Target my opponent</button>
      </div>
    </div>
    <div id="app-sea-container">
      <MySeaContainer
        v-if="showMyShips"
        :alphabet="alphab"
        :boats="boatsArr"
        :code="gameCode"
        :num-cols="cols"
        :num-rows="rows"
        :user="nickname"
      />
      <OpponentSeaContainer
        v-else
        :alphabet="alphab"
        :code="gameCode"
        :num-cols="cols"
        :num-rows="rows"
        :user="nickname"
      />
    </div>
</template>

<script>
import NetworkService from '../services/app.service';
import MySeaContainer from './MySeaContainer.vue';
import OpponentSeaContainer from './OpponentSeaContainer.vue';

export default {
  components: {
    MySeaContainer,
    OpponentSeaContainer,
  },
  data() {
    return {
      alphab: [],
      boatsArr: [],
      cols: 0,
      rows: 0,
      gameCode: '', // 'battleship rebels', // '',
      gameInProgress: false, // default to false but true for testing
      showMyShips: true,
      nickname: '',
    };
  },
  async mounted() {
    // get params from the route
    this.gameCode = this.$route.params.code;
    this.nickname = this.$route.params.user;

    // create app service
    const appService = new NetworkService();
    this.alphab = await appService.getAlphabet();
    // get the width of the device in px
    const width = this.getWidth();
    // metadata from api given the current width
    const metadata = await appService.getMetadata(width);
    this.rows = metadata.numRows;
    this.cols = metadata.numCols;
    this.boatsArr = metadata.boats;
    // https://fionnachan.medium.com/dynamic-number-of-rows-and-columns-with-css-grid-layout-and-css-variables-cb8e8381b6f2
    document.documentElement.style.setProperty('--rowNum', this.rows);
    document.documentElement.style.setProperty('--colNum', this.cols);
    // get game status from api
    const status = await appService.getStatus(this.gameCode, this.nickname);
    console.log(status);
  },
  methods: {
    getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth,
      );
    },
  },
};
</script>
