<template>
  <div id="battle-creation">
    <label for="gameCodeInput">
      Game Code
      <input
        id="gameCodeInput"
        type="text"
        name="battleCode"
        placeholder="secret code"
        v-model="gameCode"
        :disabled="requestInProgress"
      />
    </label>
    <label for="nicknameInput">
      Nickname
      <input
        id="nicknameInput"
        type="text"
        name="nickname"
        placeholder="fire guacamole"
        v-model="nickname"
        :disabled="requestInProgress"
      />
    </label>
    <button @click="createGame()">create game</button>
    <button @click="joinGame()">join game</button>
  </div>
</template>

<script>
import NetworkService from '../services/app.service';

export default {
  name: 'BattleCreation',
  props: [],
  data() {
    return {
      gameCode: '',
      nickname: '',
      requestInProgress: false,
      networkService: null,
    };
  },
  methods: {
    async createGame() {
      if (this.gameCode === '' || this.nickname === '') {
        return;
      }

      this.requestInProgress = true;
      const data = await this.networkService.createGame(this.gameCode, this.nickname);
      if (data != null && typeof data === 'object') {
        this.next(data, true);
      } else {
        console.log('error', data);
      }
      this.requestInProgress = false;
    },
    async joinGame() {
      if (this.gameCode === '' || this.nickname === '') {
        return;
      }

      this.requestInProgress = true;
      const data = await this.networkService.joinGame(this.gameCode, this.nickname);
      if (data != null && typeof data === 'object') {
        this.next(data, false);
      } else {
        console.log('error', data);
      }
      this.requestInProgress = false;
    },
    next(data, creator) {
      const user = creator ? data.creator : data.joiner;
      this.$router.push({ name: 'rebels', params: { code: data.id, user } });
    },
  },
  async mounted() {
    this.networkService = new NetworkService();
  },
};
</script>

<style scoped>
 label {
  background-color: white;
  /* border-radius: 0.3em; */
  padding: 0.1em;
 }
</style>
